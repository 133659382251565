<template>
	<div>
		<NavBar
	    	style="max-height: 50px;margin-bottom: 10px;"
	    	:username="currentUserName"
	    	:pageName="pageName"
	    ></NavBar>
		<v-app  style="background-color: white; scroll-behavior: smooth !important;">
            <div style="background-color: #f5faff;">
            	<div class="row">
            		<div class="col-xs-3 col-sm-3 col-md-3 col-lg-2">
                        <sideBar :pageName="pageName"></sideBar>
                    </div>
                    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-10">
						<div class="bannerMainClass pa-2">
							<div class="d-flex" style="justify-content: space-between;">
							    <div style="width: 30%" align="left">
							        <span  style="font-weight: 800;  font-size: 12pt;text-transform: uppercase;   letter-spacing: 1px;" class="pl-2">
							        <h3>View all Banners</h3>
							        </span>
							    </div>
							    <div class="d-flex ma-5">
							        <div class="ml-2">
							            <v-btn class="primary text-right" @click="openAddNewBanner()" style="background-color: #1467BF;">Add New Banner</v-btn>
							        </div>
							    </div>
							</div>
							<div>
								<TableComponent
								:tableItem="offerDetails" 
								:tableTitle="tableHeaders" 
								:customeSlotName="customeSlotName"
								@actionClicked="callRespectiveAPI"
								@switchClicked="callRespectiveAPI"
								/>
							</div>
							<div>
								<v-row justify="center">
									<v-dialog v-model="addNewBannerDialogFlag" persistent max-width="500">
										<v-card>
											<v-card-title style="word-break:normal">
												<p> Add New Banner</p>
											</v-card-title>
											<v-card-text>
												<v-text-field v-model="bannerName" label="Banner Name" required :disabled='editFlag' :rules="[alphaNumericRule]"/>
												<v-file-input v-model="webBannerImage" v-if='!editFlag' label="Banner Image" @change="getBase64String" required />
												<v-text-field v-model="webBannerImage" label="Banner Image" v-else :disabled='editFlag' />
												<v-file-input v-model="mobileBannerImage" v-if='!editFlag' label="Mobile Banner Image" @change="getBase64String" required />
												<v-text-field v-model="mobileBannerImage" label="Mobile Banner Image" v-else :disabled='editFlag' />
												<v-text-field v-model="positionValue" :min="0" type="number" label="Position" :rules="[wholeNumberRule]" />
												<v-menu v-model="startDateMenu" ref="startDateMenu" offset-y transition="scale-transition" :close-on-content-click="false">
													<template v-slot:activator="{ on, attrs }">
														<v-text-field v-model="startDate" class="rounded-lg" style="border-color: #E0E0E0;" solo flat outlined
															placeholder="Start Date"
															v-bind="attrs"
															v-on="on"
															required
															background-color="#F4F6FA"
														/>
													</template>
													<v-date-picker v-model="startDate" required @change="startDateMenu = false" />
													
												</v-menu>
												<v-menu v-model="endDateMenu" ref="endDateMenu" offset-y transition="scale-transition" :close-on-content-click="false">
													<template v-slot:activator="{ on, attrs }">
														<v-text-field v-model="endDate" required class="rounded-lg" style="border-color: #E0E0E0;" solo flat outlined
															placeholder="End Date"
															v-bind="attrs"
															v-on="on"
															background-color="#F4F6FA"
														/>
													</template>
													<v-date-picker required v-model="endDate" @change="endDateMenu = false" />
												</v-menu>
												<v-autocomplete 
												v-model="redirectToValue" 
												label="Redirect To" 
												:items="routeNames"
												required
												/>
											</v-card-text>
											<v-card-actions class="justify-space-between">
												<v-btn color="primary" @click="addNewBannerDialogFlag = false" style="background-color: #1467BF;">Close</v-btn>
												<v-btn color="primary" :disabled="disableOkButton" @click="updateBanner()" style="background-color: #1467BF;" v-if="editFlag">OK</v-btn>
												<v-btn color="primary" :disabled="disableOkButton" @click="addNewBanner()" style="background-color: #1467BF;" v-else>OK</v-btn>
											</v-card-actions>
										</v-card>
									</v-dialog>
								</v-row>
							</div>
						</div>
					</div>
				</div>
			</div>
		</v-app>
	</div>
</template>

<script>
import router from '@/router';
import axios from "axios";
export default {
	name: "BannerPage",
	computed: {
		wholeNumberRule() {
			return (value) => /^\d+$/.test(value) || 'Only whole numbers are allowed.';
		},
		alphaNumericRule() {
			return (value) => /^[a-zA-Z0-9]*$/.test(value) || 'Only AlphaNumeric characters are allowed.';
		},
		disableOkButton() {
			if(!this.editFlag) {
				return !(this.bannerName && this.webBannerImage && this.mobileBannerImage && this.positionValue && this.redirectToValue && this.startDate && this.endDate);
			} else {
				if(this.finalEditFlag) {
					return false;
				} else {
					return true;
				}
			}
		}
	},
	components: {
		TableComponent: () => import("../../../components/tableComponent.vue"),
    	NavBar: () => import("../../../views/navbar.vue"),
    	SideBar: () => import("../../../views/sideBar.vue")
	},
	data() {
		return {
			bannerName: '',
			validWebBannerImageFormat: false,
			validWebBannerImageSize: false,
			validMobileBannerImageFormat: false,
			validMobileBannerImageSize: false,
			editFlag: false,
			finalEditFlag: false,
			finalEditValue: [],
			webBannerImage: null,
			webBannerImageName: '',
			mobileBannerImage: null,
			mobileBannerImageName: '',
			redirectToValue: '',
			startDateMenu: false,
			endDateMenu: false,
			positionValue: '',
			startDate: '',
			endDate: '',
			routeNames: [],
			addNewBannerDialogFlag: false,
			availableStatusValue: false,
			tableHeaders: [
                { text: 'Banner Name', value: 'bannerName', sortable: false},
				{ text: 'Position', value: 'position', sortable: false},
				{ text: 'Redirect To', value: 'redirectTo', sortable: false},
                { text: 'Last Updated By', value: 'lastUpdatedBy', sortable: false },
                { text: 'Available Status', value: 'availableStatus', sortable: false },
                { text: 'Actions', value: 'actions', class:'actionClass', sortable: false}
            ],
			offerDetails: [],
			customeSlotName: [],
			isLoading: true,
			currentUserName: '',
			pageName: 'BannerPage'
		};
	},
	mounted() {
		this.pageName = "BannerPage";
		this.currentUser = this.$cookies.get("token");

		if (!this.currentUser) {
			this.getAllBanners();
			this.routeNames = this.getRouteNames();
			this.renderTableComponent();
		} else {
			this.getAllBanners();
			this.routeNames = this.getRouteNames();
			this.renderTableComponent();
		}
	},
	watch:{
		webBannerImage: function(e) {
			if(this.webBannerImage && !this.editFlag) {
				const newFileReader = new FileReader();
				newFileReader.readAsDataURL(this.webBannerImage);
				newFileReader.onload = function(event) {
					let img = new Image();
					img.src = event.target.result;
					img.onload = function () {
						if (!(img.width == 1520 && img.height == 380)) {
							window.alert("Please upload the image with 1520 x 380 dimension")
							this.webBannerImage = '';
						} else {
							this.validWebBannerImageSize = true;
							const fileReader = new FileReader();
							fileReader.onloadend = () => {
								const arrayBuffer = fileReader.result;
								const hexCode = this.convertArrayBufferToHex(arrayBuffer);
								if (hexCode.startsWith('ffd8ff')) { 
									this.validWebBannerImageFormat = true;
								} else if (hexCode.startsWith('89504e470d0a1a0a')) {
									this.validWebBannerImageFormat = true;
								} else if (hexCode.startsWith('474946383961') || hexCode.startsWith('474946383761')) {
									this.validWebBannerImageFormat = true;
								} else if (hexCode.startsWith('424d')) {
									this.validWebBannerImageFormat = true;
								} else if (hexCode.startsWith('3c73')) {
									this.validWebBannerImageFormat = true;
								} else {
									this.validWebBannerImageFormat = false;
									this.webBannerImage = '';
									window.alert("Invalid Image Format");
								}
							};
							fileReader.readAsArrayBuffer(this.webBannerImage);
						}
					}.bind(this);
				}.bind(this);
			}
		},
		mobileBannerImage: function(e) {
			if(this.mobileBannerImage && !this.editFlag) {
				const newFileReader = new FileReader();
				newFileReader.readAsDataURL(this.mobileBannerImage);
				newFileReader.onload = function(event) {
					let img = new Image();
					img.src = event.target.result;
					img.onload = function () {
						if (!(img.width == 335 && img.height == 188)) {
							window.alert("Please upload the image with 335 x 188 dimension")
							this.mobileBannerImage = '';
						} else {
							this.validMobileBannerImageSize = true;
							const fileReader = new FileReader();
							fileReader.onloadend = () => {
								const arrayBuffer = fileReader.result;
								const hexCode = this.convertArrayBufferToHex(arrayBuffer);
								if (hexCode.startsWith('ffd8ff')) { 
									this.validMobileBannerImageFormat = true;
								} else if (hexCode.startsWith('89504e470d0a1a0a')) {
									this.validMobileBannerImageFormat = true;
								} else if (hexCode.startsWith('474946383961') || hexCode.startsWith('474946383761')) {
									this.validMobileBannerImageFormat = true;
								} else if (hexCode.startsWith('424d')) {
									this.validMobileBannerImageFormat = true;
								} else if (hexCode.startsWith('3c73')) {
									this.validMobileBannerImageFormat = true;
								} else {
									this.validMobileBannerImageFormat = false;
									this.mobileBannerImage = '';
									window.alert("Invalid Image Format");
								}
							};
							fileReader.readAsArrayBuffer(this.mobileBannerImage);
						}
					}.bind(this);
				}.bind(this);
			}
		},
		positionValue: function() {
			this.makeFinalEditFlag();
        }, 
		startDate: function() {
			this.makeFinalEditFlag();
        }, 
		endDate: function() {
			this.makeFinalEditFlag();
        }, 
		redirectToValue: function() {
			this.makeFinalEditFlag();   
        }
    },
	methods: {
		makeFinalEditFlag() {
			if(this.editFlag) {
				if ((this.finalEditValue.display_data['redirectTo'] != this.redirectToValue)
					|| (this.finalEditValue.display_data['endDate'] != this.endDate) 
					|| (this.finalEditValue.display_data['startDate'] != this.startDate) 
					|| (this.finalEditValue.display_data['position'] != this.positionValue))
				{
					this.finalEditFlag = true;	
				} else {
					this.finalEditFlag = false;
				}
			}
		},
		convertArrayBufferToHex(arrayBuffer) {
			const view = new DataView(arrayBuffer);
			let hex = '';
			for (let i = 0; i < view.byteLength; i++) {
				const value = view.getUint8(i).toString(16).padStart(2, '0');
				hex += value;
			}
			return hex;
        },
		callRespectiveAPI(functionName, rowObject) {
            this[functionName](rowObject);
        },
		changeAvailableStatus(rowObject) {
			this.bannerName = rowObject.bannerName;
			this.availableStatus = rowObject.availableStatus;
			let changeAvailableStatusData = {
				bannerName: rowObject.bannerName,
				availableStatus: rowObject.availableStatus
			}

			axios
			.post(process.env.VUE_APP_EPHARMACY_BACKEND_URL + "/banners/changeAvailableStatus", changeAvailableStatusData)
			.then((changeAvailableStatusResponse) => {
				this.isLoading = false;
			})
			.catch((changeAvailableStatusError) => {
				if (changeAvailableStatusError.response.status == 401) {
					window.alert("Sorry !! You are unauthorized !!");
					this.$router.push({
						name: "AdminLogin",
					});
				} 
				if (changeAvailableStatusError.response.status == 400) {
					window.alert(changeAvailableStatusError.response.data.message);
				}
			});
		},
		getBase64String(e) {
			if(this.webBannerImage) {
				let reader = new FileReader();
				reader.readAsDataURL(e);
				reader.onload = () => {
					this.webBannerImageName = e.name;
					this.base64ForWebBannerImageURL =  reader.result;
				}
			}
			if(this.mobileBannerImage) {
				let reader = new FileReader();
				reader.readAsDataURL(e);
				reader.onload = () => {
					this.mobileBannerImageName = e.name;
					this.base64ForMobileBannerImageURL =  reader.result;
				}
			}
		},
		getAllBanners() {
			axios
			.post(process.env.VUE_APP_EPHARMACY_BACKEND_URL + "/banners/getAllBanners", {})
			.then((getAllBannersResponse) => {
				let res = getAllBannersResponse;
				if(res && res.data && res.data.data && res.data.data.Items && res.data.data.Items.length > 0) {
					this.offerDetails = [];
					for (let item of res.data.data.Items) {
						let availableStatus = false;
						if (item.status === 'ACTIVE') {
							availableStatus = true;
						}
						this.offerDetails.push({
							'bannerName': item.sk,
							'position': item.display_data.position,
							'startDate': item.display_data.startDate,
							'endDate':  item.display_data.endDate,
							'createdBy': item.CreatedBy,
							'redirectTo': item.display_data.redirectTo,
							'lastUpdatedBy': item.UpdatedBy,
							'createdAt': item.CreatedAt,
							'availableStatus': availableStatus,
							'actions': '',
						});
					}
				}
			})
			.catch((getAllBannersError) => {
				console.error('getAllBannersError', getAllBannersError);
				if (getAllBannersError.response.status == 401) {
					window.alert("Sorry !! You are unauthorized !!");
					this.$router.push({
						name: "AdminLogin",
					});
				}
			});
		},
		openEditBannerDialog(rowObject) {
			this.editFlag = true;
			this.finalEditFlag = false;
			let getBannerData = {
				bannerName: rowObject.bannerName
			}
			axios
			.post(process.env.VUE_APP_EPHARMACY_BACKEND_URL + "/banners/getParticularBanner", getBannerData)
			.then((getParticularBannerResponse) => {
				let res = getParticularBannerResponse;
				if(res && res.data && res.data.data && res.data.data.Items && res.data.data.Items.length > 0) {
					let bannerData = res.data.data.Items[0];
					this.finalEditValue = bannerData;
					this.bannerName = bannerData.sk;
					this.statusValue = bannerData.status;
					this.positionValue = bannerData.display_data.position;
					this.webBannerImage = bannerData.display_data.webBannerImageURL;
					this.webBannerImageURL = bannerData.display_data.webBannerImageURL;
					this.mobileBannerImage = bannerData.display_data.mobileBannerImage;
					this.mobileBannerImageURL = bannerData.display_data.mobileBannerImageURL;
					this.redirectToValue = bannerData.display_data.redirectTo;
					this.startDate = bannerData.display_data.startDate;
					this.endDate = bannerData.display_data.endDate;
					this.addNewBannerDialogFlag = true;
				}
			})
			.catch((getParticularBannerError) => {
				console.error('getParticularBannerError', getParticularBannerError);
				if (getParticularBannerError.response.status == 401) {
					window.alert("Sorry !! You are unauthorized !!");
					this.$router.push({
						name: "AdminLogin",
					});
				}
			});
		},
		updateBanner() {
			if(this.editFlag) {
				let updateBannerData = {
					bannerName: this.bannerName,
					webBannerImage: this.webBannerImageName,
					webBannerImageURL: this.webBannerImageURL,
					mobileBannerImage: this.mobileBannerImageName,
					mobileBannerImageURL: this.mobileBannerImageURL,
					status: this.statusValue,
					position: this.positionValue,
					redirectTo: this.redirectToValue,
					startDate: this.startDate,
					endDate: this.endDate,
					createdBy: 'userId2',
					updatedBy: 'userId2'
				}
				axios
				.post(process.env.VUE_APP_EPHARMACY_BACKEND_URL + "/banners/updateBanner", updateBannerData)
				.then((updateBannerResponse) => {
					this.addNewBannerDialogFlag = false;
					this.editFlag = false;
					this.getAllBanners();
				})
				.catch((updateBannerError) => {
					console.error('updateBannerError', updateBannerError.response);
					if (updateBannerError.response.status == 401) {
						window.alert("Sorry !! You are unauthorized !!");
						this.$router.push({
							name: "AdminLogin",
						});
					} 
					if (updateBannerError.response.status == 400) {
						window.alert(updateBannerError.response.data.message);
					}
					this.addNewBannerDialogFlag = true;
					this.editFlag = true;
				});

			}
		},
		deleteBanner(rowObject) {
			let deleteBannerData = {
				bannerName: rowObject.bannerName
			}
			axios
			.post(process.env.VUE_APP_EPHARMACY_BACKEND_URL + "/banners/deleteBanner", deleteBannerData)
			.then((deleteBannerResponse) => {
				let res = deleteBannerResponse;
				if(res && res.status === 200) {
					this.getAllBanners();
				} else {
					this.isLoading = false;
				}
			})
			.catch((deleteBannerError) => {
				console.error('deleteBannerError', deleteBannerError);
				if (deleteBannerError.response.status == 401) {
					window.alert("Sorry !! You are unauthorized !!");
					this.$router.push({
					name: "AdminLogin",
					});
				}
			});
		},
		getRouteNames() {
			return router.options.routes
            .filter(route => route.visibility === true)
            .map(route => route.name);
		},
		openAddNewBanner() {
			this.addNewBannerDialogFlag = true;
		},
		addNewBanner() {
			let addBannerData = {
				bannerName: this.bannerName,
				webBannerImage: this.webBannerImageName,
				// webBannerImageURL: this.base64ForWebBannerImageURL,
				mobileBannerImage: this.mobileBannerImageName,
				// mobileBannerImageURL: this.base64ForMobileBannerImageURL,
				position: this.positionValue,
				redirectTo: this.redirectToValue,
				startDate: this.startDate,
				endDate: this.endDate,
				createdBy: 'userId2',
				updatedBy: 'userId2'
			}
			axios
			.post(process.env.VUE_APP_EPHARMACY_BACKEND_URL + "/banners/addBanner", addBannerData)
			.then((addBannerResponse) => {
				this.addNewBannerDialogFlag = false;
				this.getAllBanners();
			})
			.catch((addBannerError) => {
				console.error('addBannerError', addBannerError.response);
				if (addBannerError.response.status == 401) {
					window.alert("Sorry !! You are unauthorized !!");
					this.$router.push({
						name: "AdminLogin",
					});
				} 
				if (addBannerError.response.status == 400) {
					window.alert(addBannerError.response.data.message);
				}
			});
		},
		renderTableComponent() {			
			let availableStatus = {
				'headerValue':'availableStatus',
				'ComponenetType': 'vSwitch',
				'objectToRender': [
					{
						'functionToEmit': 'changeAvailableStatus'
					}
				]
			}
			let actionButtons = {
				'headerValue':'actions',
				'ComponenetType': 'button',
				'objectToRender': [
					{
						'iconSrc' : "https://s3iconimages.mymedicine.com.mm/edit.svg",
						'functionToEmit': 'openEditBannerDialog'
					},
					{
						'iconSrc' : "https://s3iconimages.mymedicine.com.mm/delete.svg",
						'functionToEmit': 'deleteBanner'
					}
				],
			};
			this.customeSlotName.push(availableStatus, actionButtons);
        },
	},
};
</script>
<style scoped>
.bannerMainClass {
	overflow: hidden;
	/* height: 100%; */
	/* width: 100%; */
}
.actionClass{
    display: flex !important;
    flex-direction: row !important;
	text-align: center;
}
</style>
